import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import configs from '~/env.json'

import * as Styled from './styles'

import { Container, Drawer, DrawerBody, DrawerContent, DrawerHeader, Flex, FloraButton, FloraTypography } from '@grupoboticario/flora-react'
import { HouseIcon, MagnifyingGlassIcon, CrossCircleIcon, ThreeLinesHorizontal1Icon, DocumentChartIcon, QuestionMarkCircleIcon, ShieldCheckIcon, ChatDotsIcon } from '@grupoboticario/flora-react-icons'

import useTracking from '~/services/tracking'
import { CAMPAIGN_STATUS } from '~/helpers/constants'
import { getThemeForMenuItems } from '~/helpers/themeSelector'
import BrandLogo from '~/assets/images/logo/brands'
import ModalMgm from '~/components/modal-mgm'

export default () => {
  const tracking = useTracking()

  const [menuOpen, setMenuOpen] = useState(false)

  const {
    campaignId,
    consumerMenu,
    campaignStatus
  } = useSelector(state => state.campaignReducers.campaign)

  const isConsumerMenuValid = typeof consumerMenu === 'object' && consumerMenu.length > 0

  const isMenuVisible = campaignStatus === CAMPAIGN_STATUS.ENABLED && isConsumerMenuValid
  const hasConsumerMenu = isConsumerMenuValid

  const renderMenuIconMap = (iconText) => {
    const icons = {
      'Página Inicial': <HouseIcon />,
      'Consulte seu Voucher': <MagnifyingGlassIcon />,
      'Regulamento': <DocumentChartIcon />,
      'Dúvidas frequentes': <QuestionMarkCircleIcon />,
      'Política de Privacidade': <ShieldCheckIcon />,
      'Fale com o PROCON': <ChatDotsIcon />,
      'default': null
    }

    return icons[iconText] || icons['default']
  }

  const RenderMenuItem = ({ to, trackingSlug, Icon, Title, target = false }) => {
    const theme = getThemeForMenuItems(configs?.REACT_APP_BRANCH)

    return (
      <Flex align="center" css={{ marginBottom: '$4' }}>
        {Icon && (
          <span
            style={{
              color: theme.colors.actionableDefault,
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: '16px',
            }}
            aria-hidden="true"
          >
            {Icon}
          </span>
        )}
        <FloraTypography
          viewport="desktop"
          fontSize="bodyLargeStandard"
          fontWeight="medium"
          style={{ color: theme.colors.actionableDefault }}
        >
          <Link
            to={to}
            target={target || '_self'}
            onClick={() => tracking({ event: trackingSlug })}
            aria-label={Title}
            style={{ color: theme.colors.actionableDefault }}
          >
            {Title}
          </Link>
        </FloraTypography>
      </Flex>
    )
  }

  const handleMenuToggle = () => {
    tracking({ event: menuOpen ? 'header_menu_abre_click' : 'header_menu_sair_click' })
    setMenuOpen(!menuOpen)
  }

  const handleBackgroundClick = () => {
    tracking({ event: 'header_menu_sair_click' })
    setMenuOpen(false)
  }

  const handleCloseMenu = () => setMenuOpen(false)


  return (
    <>
      <Styled.Header>
          <Container size="large">
            {isMenuVisible && (
              <Flex align="center" justify="space-between">
                <Flex align="center">
                  <Flex align="center" css={{ marginRight: '16px' }}>
                    <FloraButton
                      size="small"
                      surfaceColor="dark"
                      aria-label={menuOpen ? 'Fechar menu' : 'Abrir menu'}
                      css={{
                        width: '$8',
                        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.22)'
                      }}
                    >
                      <Flex
                        align="center"
                        onClick={handleMenuToggle}
                      >
                        {menuOpen ? <CrossCircleIcon /> : <ThreeLinesHorizontal1Icon />}
                      </Flex>
                    </FloraButton>
                  </Flex>
                  <Flex align="center">
                    <Link
                      to={`/${campaignId}/`}
                      onClick={() => tracking({ event: 'header_logo_click' })}
                      aria-label='Voltar para a home'
                    >
                      <BrandLogo />
                    </Link>
                  </Flex>
                </Flex>
                <Flex align="center">
                  <ModalMgm showOnlyIcon />
                </Flex>
              </Flex>
            )}
        </Container>
      </Styled.Header>

      <Flex
        css={{
          backgroundColor: '$backgroundPrimary',
        }}
        onClick={handleBackgroundClick}
      >
        <Drawer
          open={menuOpen}
          onClose={handleCloseMenu}
          aria-labeledby="menu"
        >
          <DrawerContent
            side="left"
          >
            <DrawerHeader
              css={{
                marginTop: '200px'
              }}
            >
             <FloraTypography
              fontSize="subtitle"
              fontWeight="bold"
              id="menu"
            >
              Menu
            </FloraTypography>
            </DrawerHeader>
            <DrawerBody>
              {hasConsumerMenu && consumerMenu.map((menuItem, index) => {
                const isExternal =  menuItem.link.length > 0 && menuItem.link[0] !== '/'
                const Icon = renderMenuIconMap(menuItem?.description) || null

                return (
                  <RenderMenuItem
                    key={`menu_item_${index}`}
                    to={isExternal ? { pathname: menuItem?.link } : `/${campaignId}${menuItem?.link}`}
                    trackingSlug={`header_menu_item_${menuItem?.item}_click`}
                    Icon={Icon}
                    Title={menuItem?.description}
                    target={isExternal ? '_blank' : '_self'}
                  />
                )
              })}

            </DrawerBody>
            <FloraButton
                size="medium"
                surfaceColor="light"
                onClick={handleCloseMenu}
                hierarchy="secondary"
                css={{ margin: "$6" }}
                aria-label='Voltar para a home'
              >
              Voltar
            </FloraButton>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  )
}
