import configs from '~/env.json'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App'

if (configs?.NODE_ENV) {
  ReactDOM.createRoot(
    document.getElementById("root"),
  )
  .render(
      <App />,
  );
}
