import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useTracking from '~/services/tracking'

import { Button, Grow } from '@material-ui/core'

import { Flex, FloraButton, FloraTypography, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader } from '@grupoboticario/flora-react'
import { CopySimpleIcon, WhatsappLogoIcon } from '@grupoboticario/flora-react-icons'

import { ThreeLinkedDotsIcon } from '@grupoboticario/flora-react-icons'

import { WhatsappShare, getLink, getOneLink } from '~/helpers/shares'

const ModalMgm = ({ open = false, showOnlyIcon = false, shareButtonLabel }) => {
  const tracking = useTracking()

  const [copy, setCopy] = useState(false)
  const [openShare, setOpenShare] = useState(open)

  const {
    prms,
    campaignId,
    createdThrough,
    layout: {
      MGM_HOME_COMPARTILHAMENTO_LINK,
      MGM_HOME_COMPARTILHAMENTO_LINK_WPP,
      MGM_HOME_COMPARTILHAMENTO_TEXTO_WPP
    }
  } = useSelector((state) => state.campaignReducers.campaign)

  const appAccess = useSelector((state) => state.campaignReducers.appAccess)

  const isShareAvailable = createdThrough === 'backoffice' || (
    createdThrough === 'sheet' &&
    MGM_HOME_COMPARTILHAMENTO_LINK &&
    MGM_HOME_COMPARTILHAMENTO_LINK_WPP &&
    MGM_HOME_COMPARTILHAMENTO_TEXTO_WPP
  )

  const handleOpen = (source) => {
    const event = source === 'header' ? 'boas_vindas_mgm_modal_open_by_header' : 'boas_vindas_mgm_modal_open'
    tracking({ event })
    setOpenShare(true)
  };

  const handleClose = () => {
    tracking({ event: 'boas_vindas_mgm_modal_close' })
    setOpenShare(false)
  }

  return (
    <>
      {isShareAvailable && (
        <>
          {
            showOnlyIcon ? (
              <FloraButton
                onClick={() => handleOpen('header')}
                size="small"
                surfaceColor="dark"
                aria-label='Compartilhar'
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleOpen('header')
                  }
                }}
                css={{
                  width: '$8',
                  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.22)'
                }}
              >
                <ThreeLinkedDotsIcon />
              </FloraButton>
            ) : (
              <Grow in>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  onClick={() => handleOpen('default')}
                  startIcon={<ThreeLinkedDotsIcon />}
                >
                  {shareButtonLabel}
                </Button>
              </Grow>
            )
          }

          <Flex
              css={{
                backgroundColor: '$backgroundPrimary'
              }}
            >
              <Modal
                open={openShare}
                onClose={handleClose}
                arial-labelledby="share-modal-title"
                aria-describedby="share-modal-description"
              >
                <ModalContent
                  size="standard"
                  >
                  <ModalCloseButton
                    onClick={handleClose}
                    aria-label="Fechar modal"
                  />
                    <ModalHeader
                      id="share-modal-title"
                      css={{ margin: "0 1rem 0 0" }}
                    >
                      Compartilhe a campanha com seus amigos
                    </ModalHeader>
                  <ModalBody
                    id="share-modal-description"
                  >
                    <FloraTypography fontSize="bodyLargeStandard">{prms?.[0]?.title}</FloraTypography>
                  </ModalBody>
                  <ModalFooter css={{ display: "block" }}>
                    <CopyToClipboard
                      text={appAccess ? getOneLink(campaignId, 'copiarlink') : getLink(campaignId, 'copy')}
                      onCopy={() => {
                        tracking({ event: 'boas_vindas_mgm_copy_link' })
                        setCopy(true)
                      }}
                    >
                      <FloraButton
                        size="medium"
                        isFull
                        has="iconLeft"
                        icon={<CopySimpleIcon />}
                        aria-label="Copiar link"
                      >
                        {copy ? 'Copiado!' : 'Copiar link'}
                      </FloraButton>
                    </CopyToClipboard>
                    <Link
                      to={{
                        pathname: WhatsappShare(
                          `Eu já garanti o meu mimo! Garanta o seu também: ${
                            appAccess ? getOneLink(campaignId, 'whatsapp') : getLink(campaignId, 'wpp')
                          }`
                        ),
                      }}
                      target="_blank"
                      onClick={() => tracking({ event: 'boas_vindas_mgm_wpp_share' })}
                    >
                      <FloraButton
                        surfaceColor="dark"
                        size="medium"
                        isFull
                        has="iconLeft"
                        icon={<WhatsappLogoIcon />}
                        aria-label="Compartilhar no WhatsApp"
                      >
                        Mandar por WhatsApp
                      </FloraButton>
                    </Link>
                  </ModalFooter>
                </ModalContent>
              </Modal>
          </Flex>
        </>
      )}
    </>
  )
}

export default ModalMgm
