export const statesOfBrazilFull = {
  AC: 'Acre',
  AL: 'Alagoas',
  AM: 'Amazonas',
  AP: 'Amapá',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MG: 'Minas Gerais',
  MS: 'Mato Grosso do Sul',
  MT: 'Mato Grosso',
  PA: 'Pará',
  PB: 'Paraíba',
  PE: 'Pernambuco',
  PI: 'Piauí',
  PR: 'Paraná',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RO: 'Rondônia',
  RR: 'Roraima',
  RS: 'Rio Grande do Sul',
  SC: 'Santa Catarina',
  SE: 'Sergipe',
  SP: 'São Paulo',
  TO: 'Tocantins'
}

export const statesOfBrazil = Object.entries(statesOfBrazilFull).map(([acron]) => acron)

export const CHANNELS = {
  STORE: 'store',
  ECOMM: 'ecomm',
  RESELLER: 'reseller',
  WHATSAPP_STORE: 'whatsappStore',
  PARTNER: 'partner'
}

export const CALENDAR_TYPES = {
  GMAIL: 'gmail',
  OUTLOOK: 'outlook'
}

export const CAMPAIGN_STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
}

export const CONSUMER_ORIGIN = {
  GB: 'gbCustomer',
  LAST_ENROLMENT: 'lastEnrolment'
}

export const VOUCHER_STATUSES = {
  BOOKED: 'booked',
  CREATED: 'created',
  EXPIRED: 'expired',
  PENDING: 'pending',
  REDEEMED: 'redeemed'
}

export const MODALITIES = {
  RAFFLE: 'SORTEIO',
  CASHBACK: 'CASHBACK',
  INDICATION: 'INDICACAO',
  FLOW_ACTION: 'ACAO_DE_FLUXO',
  MASSIVE_SAMPLING: 'AMOSTRAGEM_MASSIVA',
  GIFT_HUB: 'HUB_DO_PRESENTE',
  FOUND_WON: 'ACHOU_GANHOU'
}

export const STORE_OPERATION_STATUSES = {
  OPENED: 'opened',
  CLOSED: 'closed',
  SCHEDULED_CLOSING: 'scheduledClosing'
}

export const CONTENT_MODES = {
  PREVIEW: 'preview'
}

export const VIP_CONSUMERS_TYPES = {
  DIAMOND: 'diamond',
  GOLD: 'gold',
  SILVER: 'silver'
}

export const BRANCH_TYPES = {
  BOT: {
    acron: 'BOT',
    name: 'O Boticário',
    fidelity_warning: 'Uso limitado a 20% do valor total da compra',
    fidelity_url: 'https://www.boticario.com.br/minha-conta/club'
  },
  QDB: {
    acron: 'QDB',
    name: 'Quem Disse, Berenice?',
    fidelity_warning: 'Uso limitado a 20% do valor total da compra',
    fidelity_url: 'https://www.quemdisseberenice.com.br/minha-conta/club'
  },
  VULT: {
    acron: 'VULT',
    name: 'VULT',
    fidelity_warning: '',
    fidelity_url: ''
  },
  AUS: {
    acron: 'AUS',
    name: 'Australian Gold',
    fidelity_warning: '',
    fidelity_url: ''
  }
}

export const VIEW_PATH_NAME = {
  VERIFY_VOUCHER: 'verifyVoucher',
  REGISTER: 'register'
}

export const OFFER_KIND = {
  PREMIUM: 'PREMIUM',
  NORMAL: 'NORMAL'
}
